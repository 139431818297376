import React, { useEffect, useRef, useState } from "react";
import { MensajeError } from "../MensajeError";
import TicketsService from "../../services/TicketsService";
import ReCAPTCHA from "react-google-recaptcha";
import { MensajeOk } from "../MensajeOk";
import { useValidacionContacto } from "../../components/validaciones/validacionesContacto";
const REACT_APP_SITE_KEY = process.env.REACT_APP_SITE_KEY;

export const GenerarTicket = () => {
    const recaptchaRef = useRef();
    const [ticket, setTicket] = useState({
        nombre: "",
        email: "",
        mailConfirmacion: "",
        telefono: "",
        dominio: "",
        asunto: "",
        mensaje: "",
        archivos: [],
    });
    const [fileInputKey, setFileInputKey] = useState(0);
    const [captchaResponse, setCaptchaResponse] = useState("");

    const [mensajeOk, setMensajeOk] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [camposError, setCamposError] = useState(undefined);
    const fileInputRef = useRef(null);


    const { validarDatosContactoTicket } = useValidacionContacto();

    useEffect(() => {
        limpiarMensajes();
        limpiarFormulario();
    }, []);

    const limpiarMensajes = () => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: "",
        });
        setError({
            error: false,
            mensaje: "Sin errores",
        });
        setCamposError([]);
    };

    const limpiarFormulario = () => {
        setTicket({
            nombre: "",
            email: "",
            mailConfirmacion: "",
            telefono: "",
            dominio: "",
            asunto: "",
            mensaje: "",
            archivos: []
        });
        setFileInputKey((prevKey) => prevKey + 1);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };

    const limpiarAdjunto = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const generarTicket = (e) => {
        e.preventDefault();

        limpiarMensajes();
        let validacion = validarDatosContactoTicket(ticket, setError);
        if (!validacion.error) {
            TicketsService.generarTicket(ticket, "TICKET_AYUDA", captchaResponse)
                .then((response) => {
                    if (!response.respuestaOK) {
                        setError({
                            error: true,
                            mensaje: response.mensajeRespuesta,
                        });
                        if (response.camposError) {
                            procesarCamposError(response.camposError);
                        }
                    } else {
                        setMensajeOk({
                            mostrarMensaje: true,
                            mensaje: response.mensajeRespuesta,
                        });
                        limpiarFormulario();
                    }
                })
                .catch(() => {
                    setError({
                        error: true,
                        mensaje: "Ha ocurrido un error al generar el ticket.",
                    });
                })
                .finally(() => {
                    recaptchaRef.current.reset();
                });
        } else {
            if (validacion.camposError) {
                procesarCamposError(validacion.camposError);
            }
            recaptchaRef.current.reset();
        }
    };

    const procesarCamposError = (camposError) => {
        let camposConError = [];
        camposError.forEach((c) => camposConError.push(c.codigo));
        setCamposError(camposConError);
    };

    const styleError = (campo) => {
        if (camposError !== undefined) {
            return camposError.includes(campo) ? "red" : "";
        }
    };

    const handleOnChange = (e) => {
        e.preventDefault();
        let newVar = {
            ...ticket,
            [e.target.name]: e.target.value,
        };
        setTicket(newVar);
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        const maxFileCount = 3;
        const maxTotalFileSize = 5 * 1024 * 1024; // 5 MB en total
        const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"];
        const allowedExtensions = [".jpg", ".jpeg", ".png", ".gif"];
        const invalidCharacters = /[^a-zA-Z0-9._\-\s()]/;

        let errorMessages = [];
        const validFiles = [];
        let totalFileSize = 0;

        if (files.length > maxFileCount) {
            errorMessages.push(`No se pueden adjuntar más de ${maxFileCount} archivos.`);
        }

        for (const file of files) {
            totalFileSize += file.size;

            if (!allowedFileTypes.includes(file.type)) {
                errorMessages.push(`Formato no permitido: ${file.name}`);
                continue;
            }

            const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if (!allowedExtensions.includes(fileExtension)) {
                errorMessages.push(`Extensión no permitida: ${file.name}`);
                continue;
            }

            if (invalidCharacters.test(file.name)) {
                errorMessages.push(`Nombre de archivo inválido: ${file.name}. Usa solo letras, números, guiones, puntos, espacios o guiones bajos.`);
                continue;
            }

            validFiles.push(file);
        }

        if (totalFileSize > maxTotalFileSize) {
            errorMessages.push(
                `El tamaño total de los archivos supera el límite permitido de ${(maxTotalFileSize / (1024 * 1024)).toFixed(2)} MB.`
            );
        }

        if (errorMessages.length > 0) {
            setError({
                error: true,
                mensaje: `Errores encontrados:\n${errorMessages.join("\n")}`,
            });
            limpiarAdjunto();
            return;
        }
        errorMessages.push(''); // Para que no se muestre el mensaje de error

        setError({
            error: false,
            mensaje: "",
        });
        setTicket((prevState) => ({
            ...prevState,
            archivos: validFiles,
        }));
    };


    const validateFileContent = async (file) => {
        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.onload = () => {
                const arr = new Uint8Array(fileReader.result).subarray(0, 4);
                let header = "";
                for (let i = 0; i < arr.length; i++) {
                    header += arr[i].toString(16);
                }

                switch (header) {
                    case "89504e47": // PNG
                        resolve(true);
                        break;
                    case "ffd8ffe0": // JPEG
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                    case "ffd8ffe3":
                    case "ffd8ffe8":
                    case "ffd8ffdb":
                    case "ffd8ffee":
                        resolve(true);
                        break;
                    case "47494638": // GIF
                    case "474946383761":
                    case "474946383961":
                        resolve(true);
                        break;
                    default:
                        resolve(false);
                }
            };

            fileReader.onerror = () => {
                resolve(false);
            };

            fileReader.readAsArrayBuffer(file);
        });
    };




    function onChange(value) {
        setCaptchaResponse(value);
    }
    return <>

        { mensajeOk !== undefined && mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje} /> }
        { error !== undefined && error.error && <MensajeError mensajeError={error.mensaje} /> }

        <h2>Ticket</h2>

        <fieldset>
            <legend>Datos personales</legend>

            <form>
                <div className="form-item">
                    <label htmlFor="id-nombre">Nombre*</label>
                    <input type={"text"} id="id-nombre" name="nombre"
                           style={{borderColor: styleError("nombre")}}
                           value={ticket.nombre}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-mail">Correo electr&oacute;nico*</label>
                    <input type={"text"} id="id-mail" name="email"
                           style={{borderColor: styleError("email")}}
                           value={ticket.email}
                           onChange={handleOnChange}/>
                </div>
                <div className="form-item">
                    <label htmlFor="id-mail-confirmacion">Confirmar correo electrónico*</label>
                    <input type={"text"} id="id-mail-confirmacion" name="mailConfirmacion"
                           style={{borderColor: styleError("mailConfirmacion")}}
                           value={ticket.mailConfirmacion}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-telefono">Tel&eacute;fono</label>
                    <input type={"text"} id="id-telefono" name="telefono"
                           style={{borderColor: styleError("telefono")}} className="edit"
                           maxLength="30" value={ticket.telefono}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-nombre-dominio">Nombre de dominio</label>
                    <input type={"text"} id="id-dominio" name="dominio"
                           style={{borderColor: styleError("dominio")}} className="edit"
                           maxLength="30" value={ticket.dominio}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-asunto">Asunto*</label>
                    <input type={"text"} id="id-asunto" name="asunto"
                           style={{borderColor: styleError("asunto")}} className="edit"
                           value={ticket.asunto}
                           onChange={handleOnChange}/>
                </div>

                <div className="form-item">
                    <label htmlFor="id-mensaje">Mensaje*</label>
                    <textarea type={"text"} id="id-mensaje" name="mensaje"
                              style={{borderColor: styleError("mensaje")}} className="edit"
                              value={ticket.mensaje}
                              onChange={handleOnChange}
                              maxLength={2048}
                    />
                </div>
                <div className="form-item">
                    <label htmlFor="id-archivos">Adjuntar archivos (imágenes en formato JPG, PNG o GIF, máximo 5MB)</label>
                    <input
                        key={fileInputKey}
                        type={"file"}
                        id="id-archivos"
                        ref={fileInputRef}
                        multiple
                        accept="image/*,application/pdf,text/plain"
                        onChange={handleFileChange}
                    />
                </div>
                <div className={"box-captcha cfx "}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={REACT_APP_SITE_KEY}
                        onChange={onChange}
                    />
                </div>

                <div className="form-item">
                    <button className="btn right" onClick={generarTicket}>Guardar datos</button>
                </div>
            </form>
        </fieldset>


    </>

}
