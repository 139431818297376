
export function buildNuevoTicket(ticket, tipoOperacion, captchaResponse) {
    const formData = new FormData();
    const maxFileSize = 5 * 1024 * 1024; // 5 MB en bytes
    const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileCount = 10; //máxima cantidad de imágenes

    const ticketData = {
        nombre: ticket.nombre,
        email: ticket.email,
        mailConfirmacion: ticket.mailConfirmacion,
        telefono: ticket.telefono,
        dominio: ticket.dominio,
        asunto: ticket.asunto,
        mensaje: ticket.mensaje,
        captchaResponse: captchaResponse,
    };

    formData.append(
        "datosTicket",
        new Blob(
            [
                JSON.stringify({
                    ticket: ticketData,
                    tiposOperacion: tipoOperacion,
                    captcha: captchaResponse,
                }),
            ],
            { type: "application/json" }
        )
    );

    formData.append("tipoOperacion", tipoOperacion);
    formData.append("captcha", captchaResponse);

    //validar y agregar imagenes si cumplen con las restricciones
    if (ticket.archivos && ticket.archivos.length > 0) {
        if (ticket.archivos.length > maxFileCount) {
            console.warn(`Se excedió el límite máximo de ${maxFileCount} imágenes.`);
        } else {
            ticket.archivos.forEach((archivo) => {
                if (allowedFileTypes.includes(archivo.type) && archivo.size <= maxFileSize) {
                    formData.append("archivos", archivo);
                } else {
                    console.warn(
                        `Archivo no permitido: "${archivo.name}". Tipo: ${archivo.type}, Tamaño: ${(archivo.size / 1024).toFixed(2)} KB`
                    );
                }
            });
        }
    } else {
        //aagregar un archivo vacio si no hay archivos
        formData.append(
            "archivos",
            new Blob([], { type: "application/octet-stream" }),
            "empty.txt"
        );
    }

    return formData;
}
