import React, {useEffect} from 'react';
import {getOidcState, oidc_authorize} from "../../security/OidcManager";
import * as HttpService from "../../support/HttpService";

export const ValidacionAutenticado = () => {

    useEffect(() => {
        if (!getOidcState().is_authenticated) {
            const currentPath = window.location.pathname + window.location.search;
            if (currentPath.includes("administrar/mis-dominios") && currentPath.includes("dominio=")) {
                oidc_authorize(currentPath);
            } else {
                oidc_authorize(HttpService.history.location.pathname); // Usar la ruta previa
            }
        }
    }, []);

    return <></>
}