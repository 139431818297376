import React, {useEffect, useState, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import AdministracionUsuarioService from "../../../services/AdministracionUsuarioService";
import CuentaService from "../../../services/CuentaService";
import Paginador from "../../../components/tabla/Paginador";
import {Link} from 'react-router-dom';
import {ModalModificarDatosContacto} from "./acciones/ModalModificarDatosContacto";
import {ModalModificarCuenta} from "./acciones/ModalModificarCuenta";
import {MensajeOk} from "../../MensajeOk";
import DatePicker from 'react-datepicker';
import { es } from 'date-fns/locale';
import "react-datepicker/dist/react-datepicker.css";
import ModalEliminarFormaPago from './acciones/ModalEliminarFormaPago';
import {ModalLiberarDominio} from "../../../components/administrar/misDominios/modales/ModalLiberarDominio";
import {ModalEliminarDominio} from "../../../components/administrar/misDominios/modales/ModalEliminarDominio";
import {ModalRenovacionDominio} from "../../../components/administrar/misDominios/modales/ModalRenovacionDominio";
import {ModalRecuperarDominio} from "../../../components/administrar/misDominios/modales/ModalRecuperarDominio";
import {MensajeError} from "../../MensajeError";
import {ValidacionAutenticado} from "../../../components/validaciones/ValidacionAutenticado";
import FeedbackSpinner from "../../../components/feedback/FeedbackSpinner";

export const MisDominios = () => {
    const [dominios, setDominios] = useState(undefined);
    const [habilitarCuenta, setHabilitarCuenta] = useState(false);
    const [esCuentaCombo, setEsCuentaCombo] = useState(true);
    const [cuentas, setCuentas] = useState([]);
    const [cuentaSeleccionada, setCuentaSeleccionada] = useState(undefined);
    const [tiposContacto, setTiposContacto] = useState([]);
    const [nombreDominioAccion, setNombreDominioAccion] = useState('');
    const [idDominioCore, setIdDominioCore] = useState(0);
    const [checkedDominios, setCheckedDominios] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [pagActual, setPagActual] = useState(0);
    const [cantPorPag, setCantPorPag] = useState(10);
    const [cantResultados, setCantResultados] = useState();
    const [isOpenEditarContacto, setIsOpenEditarContacto] = useState(false);
    const [isOpenEditarCuenta, setIsOpenEditarCuenta] = useState(false);
    const [isOpenEliminarFormaPago, setisOpenEliminarFormaPago] = useState(false);
    const [isOpenLiberarDominio, setIsOpenLiberarDominio] = useState(false);
    const [isOpenEliminarDominio, setIsOpenEliminarDominio] = useState(false);
    const [isOpenRenovarDominio, setIsOpenRenovarDominio] = useState(false);
    const [isOpenRecuperarDominio, setIsOpenRecuperarDominio] = useState(false);
    const [mapPermisosDominio, setMapPermisosDominio] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const REACT_APP_URL_REDIRECT_VER_DOMINIO = process.env.REACT_APP_REDIRECT_VER_DOMINIO;
    const [mensajeConfirmarEliminar, setMensajeConfirmarEliminar] = useState("");
    const [mensajeConfirmarLiberar, setMensajeConfirmarLiberar] = useState("");
    const location = useLocation();
    const [cuentaId, setCuentaId] = useState("");
    const [habilitarInput, setHabilitarInput] = useState(false);
    const [opcionSeleccionada, setOpcionSeleccionada] = useState(0);
    const buttonRef = useRef(null);
    const [valorComboAlojado, setValorComboAlojado] = useState("-1");
    const [mensajeOk, setMensajeOk] = useState({
        mostrarMensaje: false,
        mensaje: ''
    })
    const [error, setError] = useState({
        error: false,
        mensaje: ''
    })
    const [isProcesando, setIsProcesando] = useState(true);

    const mensajeBuscandoDominios = 'Buscando dominios...';
    const [mensajeProcesando, setMensajeProcesando] = useState(mensajeBuscandoDominios);

    const navigate = useNavigate();

    // const [esSubmit, setEsSubmit] = useState(true);
    const [filtro, setFiltro] = useState({
        nombre : '',
        tipoRelacionUsuDom: 'TODOS',
        fechaVencimientoDesde: '',
        fechaVencimientoHasta: '',
        cuentaPago: '',
        cuentaPagoNombre: '',
        dominiosAlojados: null,
        modulo: 'FRONTEND',
        primerResultado: 0,
        cantResultados: 10
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const dominio = params.get('dominio');
        if (dominio) {
            let filtroTemp = filtro;
            filtroTemp.nombre = dominio;
            filtroTemp.cantResultados=10;
            filtroTemp.modulo='FRONTEND';
            AdministracionUsuarioService.obtenerDominiosUsuario(filtroTemp)
                .then((response) => {
                    if (response.dominios && response.dominios.length > 0) {
                        navigate('/administrar/ver-dominio', { state: { dominio: response.dominios[0] } });
                    } else {
                        setError({
                            error: true,
                            mensaje: 'No se encontró el dominio ' + dominio
                        });
                    }
                })
                .catch((error) => {
                    setError({
                        error: true,
                        mensaje: 'No se encontró el dominio ' + dominio
                    });
                });
        }
    }, [location.search]);

    useEffect(() => {
        if (location.state && location.state.mensajeOk) {
            setMensajeOk(location.state.mensajeOk);
        }
        if (location.state && location.state.error) {
            setError(location.state.error);
        }
    }, [location]);

    useEffect(() => {
        obtenerDominiosUsuarioPorPagina(0, cantPorPag, filtro, true);
        obtenerCuentasPagoUsuarioService();
        obtenerTiposContacto();
        obtenerMensajesAccionesAdministracion();
    }, [])

    useEffect(() => {
        if (location.state !==null && location.state.cuentaId !== '') {
            setCuentaId(location.state.cuentaId);
            setHabilitarInput(location.state.habilitarInput);
            if (cuentaId && cuentaId.cuenta.length > 0) {
                const cuentaEncontrada = cuentas.find(cuenta => cuenta.cuenta.cuenta === cuentaId.cuenta);
                if (cuentaEncontrada) {
                    setCuentaSeleccionada(cuentaEncontrada.cuenta.cuenta);
                    setFiltro({...filtro, cuentaPago: cuentaEncontrada.cuenta.cuenta});
                    setHabilitarCuenta(true);
                    setEsCuentaCombo(true);
                    setOpcionSeleccionada(1);
                    let filtroTmp= filtro;
                    filtroTmp.cuentaPago= cuentaId.cuenta;
                    obtenerDominiosUsuarioPorPagina(0, cantPorPag, filtroTmp, true);
                }

            }

        }

    }, [habilitarInput, cuentaId, cuentas]);




    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const fechaVencimientoHastaParam = params.get('fechaVencimientoHasta');


        if (fechaVencimientoHastaParam) {
            const [year, month, day] = fechaVencimientoHastaParam.split('-');
            const localDate = new Date(year, month - 1, day);
            setEndDate(localDate);
            setFiltro({
                ...filtro,
                fechaVencimientoHasta: fechaVencimientoHastaParam
            });
            setTimeout(() => {
                buttonRef.current.click();
            }, 100);

        }
    }, []);

    useEffect(() => {
        const estado = location.state;
        if (estado) {
            if (estado.alojado !== undefined) {
                const alojadoValue = estado.alojado === 1;
                setValorComboAlojado(estado.alojado.toString());
                setFiltro(prevFiltro => ({
                    ...prevFiltro,
                    dominiosAlojados: alojadoValue
                }));
            }
            if (estado.fechaVencimientoHasta) {
                const [year, month, day] = estado.fechaVencimientoHasta.split('-');
                const fecha = new Date(year, month - 1, day);
                setEndDate(fecha);
                handleOnChangeFecha(fecha, "datepickerHasta")
                setFiltro(prevFiltro => ({
                    ...prevFiltro,
                    fechaVencimientoHasta: estado.fechaVencimientoHasta
                }));
            }
        }
    }, [location.state]);

    useEffect(() => {
        if (filtro.dominiosAlojados !== null || filtro.fechaVencimientoHasta) {
            obtenerDominiosUsuarioPorPagina(0, cantPorPag, filtro, true);
        }
    }, [filtro.dominiosAlojados, filtro.fechaVencimientoHasta]);

    const obtenerDominiosUsuario = () => {
        AdministracionUsuarioService.obtenerDominiosUsuario(filtro)
            .then(response => {
                setCantResultados(response.dominios.length);
            })
    }

    function limpiarMensajeError() {
        setError({
            error: false,
            mensaje: ''
        });
    }

    const obtenerDominiosUsuarioPorPagina = (paginaActual, cantPorPagina, filtro, esSubmit) => {
        let filtroTemp = filtro;
        filtroTemp.nombre = filtroTemp.nombre.trim();
        if(habilitarCuenta && (filtro.cuentaPago === undefined || filtro.cuentaPago == null || filtro.cuentaPago === '' || filtro.cuentaPago ==0 )) {
            setError({
                error: true,
                mensaje: 'Debe seleccionar una cuenta'
            });
            return;
        }
        else{
            limpiarMensajeError();
        }

        if (esSubmit) {
            filtroTemp.primerResultado = 0;
            filtroTemp.cantResultados = cantPorPag;
        } else {
            filtroTemp.primerResultado = paginaActual*cantPorPagina;
            filtroTemp.cantResultados = cantPorPagina;
        }

        setMensajeProcesando(mensajeBuscandoDominios)
        setIsProcesando(true);

        AdministracionUsuarioService.obtenerDominiosUsuario(filtroTemp)
            .then(response => {
                if (response.codigo === 'OK') {
                    if (esSubmit) {
                        setPagActual(0);
                    }
                    setCantResultados(response.totalDominiosFiltro);
                    setDominios(response.dominios);
                    permisoPorDominio(response.permisosDominio);
                }
                setIsProcesando(false);
            })
            .catch(error => {
                setIsProcesando(false);
            });

    }

    const permisoPorDominio = (permisosDominio) => {
        let mapPermisosDoms = new Map();

        Object.entries(permisosDominio).map(([clave, valor]) => {
            mapPermisosDoms.set(clave, {
                nombreDominio: clave,
                esAdmin: valor.esAdministrativo,
                esFinanciero: valor.esFinanciero,
                esTecnico: valor.esTecnico,
                esTitular: valor.esTitular
            })
        })
        setMapPermisosDominio(JSON.parse(JSON.stringify(Object.fromEntries(mapPermisosDoms))));
    }

    const obtenerCuentasPagoUsuarioService = () => {
        CuentaService.getCuentasPagoUsuario()
            .then(response => {
                if (response.respCodigo === 'OK') {
                    setCuentas(response.cuentas);
                }
            })
    }

    const obtenerTiposContacto = () => {
        CuentaService.getTiposContacto()
            .then(response => {
                if (response.respuestaOk) {
                    setTiposContacto(response.tiposContacto);
                }
            }).catch(error => {
                throw error;
            })
    }

    const obtenerMensajesAccionesAdministracion = () => {
        AdministracionUsuarioService.obtenerMensajesAccionesAdministracion()
            .then((response) => {
                if (response.codigoRespuesta === "OK") {
                    setMensajeConfirmarEliminar(response.mensajeConfirmarEliminar);
                    setMensajeConfirmarLiberar(response.mensajeConfirmarLiberar);
                }
            })
    }

    const onChangeComboCuentaPago = (event) => {
        const option = Number(event.target.value);
        setOpcionSeleccionada(option);

        switch (option) {
            case 0:
                setHabilitarCuenta(false);
                setFiltro({...filtro, cuentaPago: ''});
                break;
            case 1:
                setHabilitarCuenta(true);
                setEsCuentaCombo(true);
                // if (cuentas.length > 0) {
                //     const cuentaInicial = cuentas[0].cuenta.cuenta;
                //     setCuentaSeleccionada(cuentaInicial);
                //     setFiltro({...filtro, cuentaPago: cuentaInicial});
                // }
                break;
            case 2:
                setHabilitarCuenta(true);
                setEsCuentaCombo(false);
                break;
            default:
                break;
        }
    };

    const handleOnChangeTipoAsociacion = (event) => {
        const { value } = event.target;
        setFiltro({...filtro,
            tipoRelacionUsuDom: value
        });
    }

    const handleOnChangeText = (e) => {
        const { value } = e.target;
        setFiltro({...filtro,
            nombre: value
        });
    }

    const onChangeCuenta = (event) => {
        const { value } = event.target;
        setCuentaSeleccionada(value);
        setFiltro({...filtro,
            cuentaPago: value
        });
    }

    const handleOnChangeNroCuenta = (e) => {
        const { value } = e.target;
        setFiltro({...filtro,
            cuentaPago: value
        });
    }

    const handleOnChangeFecha = (date, id) => {
        if (date) {
            switch (id) {
                case "datepickerDesde":
                    setFiltro({
                        ...filtro,
                        fechaVencimientoDesde: date
                    });
                    break;
                case "datepickerHasta":
                    setFiltro({
                        ...filtro,
                        fechaVencimientoHasta: date
                    });
                    break;
            }
        } else {
            //fecha es null
            switch (id) {
                case "datepickerDesde":
                    setFiltro({
                        ...filtro,
                        fechaVencimientoDesde: null
                    });
                    break;
                case "datepickerHasta":
                    setFiltro({
                        ...filtro,
                        fechaVencimientoHasta: null
                    });
                    break;
            }
        }
    };


    const onChangeAlojado = (e) => {
        const value = e.target.value;
        setValorComboAlojado(value);

        let alojado = undefined;

        switch (value) {
            case '0':
                alojado = false;
                break;
            case '1':
                alojado = true;
                break;
            default:
                alojado = undefined;
                break;
        }

        setFiltro(prevFiltro => ({
            ...prevFiltro,
            dominiosAlojados: alojado
        }));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setCheckedDominios([]);
        setAllChecked(false);
        obtenerDominiosUsuarioPorPagina(0, cantPorPag, filtro, true);
    }
    const handleChangePage = (paginaActual, cantXPagina) => {
        setCantPorPag(cantXPagina);
        setPagActual(paginaActual);
        setCheckedDominios([]);
        setAllChecked(false);
        obtenerDominiosUsuarioPorPagina(paginaActual, cantXPagina, filtro,false);
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ''
        });
    }

    const checkAll = (event) => {
        const { checked } = event.target;
        let dominioSeleccionados = checkedDominios.slice();
        if (checked) {
            dominios.forEach((dominio) => {
                if (!dominioSeleccionados.some((dominioSeleccionado) => {return dominio.dominio.dominioCore.nombre === dominioSeleccionado})) {
                    dominioSeleccionados.push(dominio.dominio.dominioCore.nombre);
                }
            })
        } else {
            dominioSeleccionados = [];
        }
        setAllChecked(dominioSeleccionados.length > 0);
        setCheckedDominios(dominioSeleccionados);
    }
    const checkItem = (event) => {
        const { value } = event.target;
        const { checked } = event.target;
        let dominioSeleccionados = checkedDominios.slice();
        if (checked) {
            if (!dominioSeleccionados.some((dominio) => {return value === dominio})) {
                dominioSeleccionados.push(value);
            }
        } else {
            dominioSeleccionados = dominioSeleccionados.filter((dominio) => dominio !== value);
        }
        setCheckedDominios(dominioSeleccionados);
    }

    const modificarDatosContacto = (event) => {
        event.target.blur();
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        if (checkedDominios.length > 0) {
            limpiarMensajeError();
            setIsOpenEditarContacto(true);
        } else {
            setError({
                error: true,
                mensaje: 'Debe seleccionar al menos un dominio.'
            });
        }
    }

    const modificarCuenta = (event) => {
        event.target.blur();
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        if (checkedDominios.length > 0) {
            limpiarMensajeError();
            setIsOpenEditarCuenta(true);
        } else {
            setError({
                error: true,
                mensaje: 'Debe seleccionar al menos un dominio.'
            });
        }
    }
    const eliminarFormaPago = (event) => {
        event.preventDefault();
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        if (checkedDominios.length > 0) {
            for (let i = 0; i < checkedDominios.length; i++) {
                const dominioEncontrado = dominios.find(d => d.dominio.dominioCore.nombre === checkedDominios[i]);
                if (dominioEncontrado && dominioEncontrado.dominio.dominioCore.cuentaActual === null) {
                    setError({
                        error: true,
                        mensaje: 'El dominio ' + checkedDominios[i] + ' no tiene forma de pago asociada o no tiene permisos para realizar la acción.'
                    });
                    return;
                }
            }
            limpiarMensajeError();
            setisOpenEliminarFormaPago(true);
        } else {
            setError({
                error: true,
                mensaje: 'Debe seleccionar al menos un dominio.'
            });
        }
    }

    const respuestaAccionOk = (mensaje) => {
        navigate('/administrar/mis-dominios', { state: { mensajeOk: { mostrarMensaje: true, mensaje: mensaje } } });
    }

    const handleLiberarDominio = (nombreDominio) => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        setNombreDominioAccion(nombreDominio);
        setIsOpenLiberarDominio(true);
    }
    const handleEliminarDominio = (nombreDominio, idDominioCore) => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        setIdDominioCore(idDominioCore);
        setNombreDominioAccion(nombreDominio);
        setIsOpenEliminarDominio(true)
    }

    const handleRenovarDominio = (nombreDominio, idDominioCore) => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        setIdDominioCore(idDominioCore);
        setNombreDominioAccion(nombreDominio);
        setIsOpenRenovarDominio(true);
    }

    const handleRecuperarDominio = (nombreDominio) => {
        setMensajeOk({
            mostrarMensaje: false,
            mensaje: ""
        });
        setNombreDominioAccion(nombreDominio);
        setIsOpenRecuperarDominio(true)
    }


    const limpiarFiltro = () => {
        const filtroTemp = {
            nombre : '',
            tipoRelacionUsuDom: 'TODOS',
            fechaVencimientoDesde: '',
            fechaVencimientoHasta: '',
            cuentaPago: '',
            cuentaPagoNombre: '',
            dominiosAlojados: null,
            modulo: 'FRONTEND',
            primerResultado: 0,
            cantResultados: 10
        };
        setFiltro(filtroTemp);
        return filtroTemp;
    }

    const esDominioAccesible = (dominio, rutaAlternativa = false, showVerText = false) => {
       // rutaAlternativa = true;
        const linkRuta = rutaAlternativa ? REACT_APP_URL_REDIRECT_VER_DOMINIO + dominio.dominio.dominioCore.nombre : "/administrar/ver-dominio";
        const displayText = showVerText ? "Ver" : dominio.dominio.dominioCore.nombre;

        return (
            (!dominio.preregistro && !dominio.error && dominio.dominio.dominioCore.estado.administrable) ?
                <Link to={linkRuta} state={{dominio: dominio}}>{displayText}</Link>
                :
                <span>{displayText}</span>
        )
    }
    const colspanVer = (dominio) => {
        let colSpanVer = 0;
        if (((domEliminado(dominio) && esAdministrativoOTitular(dominio)) || (domVencido(dominio && puedeRenovar(dominio))))) {
            colSpanVer = 1;
        } else if (esAdministrativoOTitular(dominio)) {
            colSpanVer = 2;
        }
        return colSpanVer;
    }

    const puedeRenovar = (dominio) => {
        return (dominio.esAdministrativo || dominio.esTitular || dominio.esFinanciero);
    }

    const esAdministrativoOTitular = (dominio) => {
        return (dominio.esAdministrativo || dominio.esTitular);
    }

    const domEliminado = (dominio) => {
        return dominio.eliminado;
    }

    const domVencido = (dominio) => {
        return dominio.vencido;
    }

    const formattedDate = (fecha) => {
        const date = new Date(fecha);
        return date.toLocaleDateString('en-GB');
    };
    useEffect(() => {
        if (habilitarInput) {
            setHabilitarCuenta(true);

        }
    }, [habilitarInput]);


    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => {
        setIsHovered(true);
    };

    const handleMouseOut = () => {
        setIsHovered(false);
    };

    return <>
        <ValidacionAutenticado/>
        { mensajeOk.mostrarMensaje && <MensajeOk mensajeOk={mensajeOk.mensaje}/>}
        { error.error && <MensajeError  mensajeError={error.mensaje}/>}
        { isOpenEditarContacto && <ModalModificarDatosContacto handleOnClose={()=>{setIsOpenEditarContacto(false)}} isOpen={isOpenEditarContacto} setIsOpen={setIsOpenEditarContacto}
                                     dominiosSeleccionados={checkedDominios} mapPermisosDominio={mapPermisosDominio}
                                     rolSeleccionado={filtro.tipoRelacionUsuDom}
                                     respuestaAccionOk={respuestaAccionOk}
        />}
        { isOpenEditarCuenta && <ModalModificarCuenta isOpen={isOpenEditarCuenta} handleOnClose={()=>{setIsOpenEditarCuenta(false)}}
                                      dominiosSeleccionados={checkedDominios} mapPermisosDominio={mapPermisosDominio}
                                      rolSeleccionado={filtro.tipoRelacionUsuDom}
                                      respuestaAccionOk={respuestaAccionOk}
        />} {isOpenEliminarFormaPago && (
        <ModalEliminarFormaPago
            isOpen={isOpenEliminarFormaPago}
            setIsOpen={() => setisOpenEliminarFormaPago(false)}
            dominiosSeleccionados={checkedDominios}
            mapPermisosDominio={mapPermisosDominio}
            rolSeleccionado={filtro.tipoRelacionUsuDom}
            respuestaAccionOk={respuestaAccionOk}
            setError={setError}
        />)} {isOpenLiberarDominio && (
        <ModalLiberarDominio isOpen={isOpenLiberarDominio} handleOnClose={()=>{setIsOpenLiberarDominio(false)}}
            nombreDominio={nombreDominioAccion}
            mensaje={mensajeConfirmarLiberar}
            respuestaAccionOk={respuestaAccionOk}
            setError={setError}
             setIsProcesando={setIsProcesando}
             setMensajeProcesando={setMensajeProcesando}
        />)} {isOpenEliminarDominio && (
        <ModalEliminarDominio isOpen={isOpenEliminarDominio} handleOnClose={()=>{setIsOpenEliminarDominio(false)}}
            idDominioCore={idDominioCore}
            nombreDominio={nombreDominioAccion}
            mensaje={mensajeConfirmarEliminar}
            respuestaAccionOk={respuestaAccionOk}
            setError={setError}
              setIsProcesando={setIsProcesando}
              setMensajeProcesando={setMensajeProcesando}
        />)} {isOpenRenovarDominio && (
        <ModalRenovacionDominio isOpen={isOpenRenovarDominio} handleOnClose={()=>{setIsOpenRenovarDominio(false)}}
                              nombreDominio={nombreDominioAccion}
                              idDominioCore={idDominioCore}
                              setMensajeOk={setMensajeOk}
                              respuestaAccionOk={respuestaAccionOk}
                                setIsProcesando={setIsProcesando}
                                setMensajeProcesando={setMensajeProcesando}

        />)}
        {isOpenRecuperarDominio && (
        <ModalRecuperarDominio isOpen={isOpenRecuperarDominio} handleOnClose={()=>{setIsOpenRecuperarDominio(false)}}
                                nombreDominio={nombreDominioAccion}
                                respuestaAccionOk={respuestaAccionOk}
                                setError={setError}
                               setIsProcesando={setIsProcesando}
                               setMensajeProcesando={setMensajeProcesando}
        />)}

        <h2>Mis dominios</h2>
        <form onSubmit={onSubmit}>

            <div className="filter">
                <div className="filtrosBasicos">

                    <div className="combo">
                        <label>Nombre</label>
                        <input type={"text"} name="filtroNombre" maxLength="50" onChange={handleOnChangeText} value={filtro.nombre}/>
                    </div>

                    <div className="combo">
                        <label>Asociación</label>
                        <select onChange={handleOnChangeTipoAsociacion} defaultValue={'TODOS'}>
                            { tiposContacto !== undefined && Object.entries(tiposContacto).map(([clave, valor]) =>
                                <option key={clave} id={clave} value={clave}>{valor}</option>
                            )}
                        </select>
                    </div>

                    <div className="combo vencimiento">
                        <label htmlFor="datepickerDesde">Vencimiento</label>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                                handleOnChangeFecha(date, "datepickerDesde");
                            }}
                            dateFormat="dd/MM/yyyy"
                            locale={es}
                            id="datepickerDesde"
                        />
                    </div>
                    <div className="combo vencimiento">
                        <label htmlFor="datepickerHasta">Hasta</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => {
                                setEndDate(date);
                                handleOnChangeFecha(date, "datepickerHasta");
                            }}
                            dateFormat="dd/MM/yyyy"
                            locale={es}
                            id="datepickerHasta"
                        />
                    </div>
                </div>

                <div className="filtoAvanzado">
                    <div className="combo">
                        <label>Cuenta Pago</label>
                        <select id="id-select-opcion-filtro-cuenta" name="opcionFiltroPago" value={opcionSeleccionada}
                                onChange={onChangeComboCuentaPago}>
                            <option value={0}>-- Seleccionar filtro --</option>
                            <option value={1}>Seleccionar cuenta</option>
                            <option value={2}>Ingresar nro. de cuenta</option>
                        </select>
                    </div>

                    {esCuentaCombo ?
                        <div id="id-div-select-cuenta" className="combo">
                            <label>Cuenta</label>
                            <select onChange={onChangeCuenta} value={cuentaSeleccionada} disabled={!habilitarCuenta}>
                                <option value={0}>-- Seleccionar cuenta --</option>
                                {cuentas !== undefined && cuentas.map(cuenta =>
                                    <option key={cuenta.id} value={cuenta.cuenta.cuenta}>{cuenta.nombre}</option>
                                )}
                            </select>
                        </div>
                        :
                        <div id="id-div-nro-cuenta" className="combo">
                            <label>Nro. Cuenta</label>
                            <input id="id-nro-cuenta"
                                   name="filtroCuentaPago"
                                   onChange={handleOnChangeNroCuenta}
                                   disabled={!habilitarCuenta}
                                   type="text"
                            />
                        </div>

                    }

                    <div className="combo">
                        <label htmlFor="id-select-opcion-filtro-alojado">Alojado</label>
                        <select value={valorComboAlojado} onChange={onChangeAlojado}>
                            <option value="-1">-- Seleccionar opción --</option>
                            <option value="1">SI</option>
                            <option value="0">NO</option>
                        </select>
                    </div>

                    <div className="combo">
                        <input type={"submit"} value={"Filtrar"} ref={buttonRef}/>
                    </div>
                </div>
            </div>
        </form>


        { isProcesando?
            <div>
                <FeedbackSpinner open={isProcesando} message={mensajeProcesando}/>
            </div> : <></>
        }

            <table className="data" id="idTablaDominios">
            <thead>
                <tr>
                    <th width="10"><input id="idChkSelectUnselectAll" type="checkbox" onChange={checkAll} checked={allChecked}/></th>
                    <th>Nombre</th>
                    <th>Vencimiento</th>
                    <th>Alojado</th>
                    <th>Titular</th>
                    <th title="Contacto Administrativo">A</th>
                    <th title="Contacto Técnico">T</th>
                    <th title="Contacto Financiero">F</th>
                    <th title="Estado dominio" style={{textAlign: 'center'}}>Estado</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                { (dominios !== undefined && dominios.length > 0) ? dominios.map((dominio) => (
                    <tr key={dominio.dominio.dominioCore.nombre}>
                        <td>
                            { (!dominio.preregistro && !dominio.error && dominio.dominio.dominioCore.estado.administrable) ?
                                <input value={dominio.dominio.dominioCore.nombre} className={"checkbox-space"} type="checkbox"
                                       onChange={checkItem} checked={checkedDominios.includes(dominio.dominio.dominioCore.nombre)}/>
                                :
                                <></>
                            }
                        </td>

                        <td>
                            { esDominioAccesible(dominio, false) }
                        </td>

                        <td>{formattedDate(dominio.dominio.dominioCore.fechaVencimiento)}</td>
                        <td>{dominio.dominio.dominioBE.alojado ? 'SI' : 'NO'}</td>
                        <td>{dominio.dominio.dominioBE.titular.email}</td>



                        <td className={dominio.esAdministrativo ? "contact-yes" : "contact-no"}></td>
                        <td className={dominio.esTecnico ? "contact-yes" : "contact-no"}></td>
                        <td className={dominio.esFinanciero ? "contact-yes" : "contact-no"}></td>

                        <td className="estado"  style={{textAlign: 'center'}}>
                            { dominio.eliminado &&
                                <span className="dominio-eliminado tooltip" title="Dominio eliminado"></span>
                            }

                            { dominio.vencido &&
                                <span className="dominio-vencido tooltip" title="Dominio vencido"></span>
                            }

                            { !dominio.dominio.tieneCuentaActual &&
                                    <span className="dominio-pago-asociado tooltip" title="Sin forma de pago asociada"></span>
                            }

                            { !dominio.eliminado && !dominio.vencido && dominio.dominio.tieneCuentaActual &&
                                <span className="dominio-registrado tooltip" title="Dominio registrado"></span>
                            }

                        </td>

                        { dominio.error ?
                            <td colSpan={3} className="edit">Error</td>
                            :
                            <>
                            { dominio.preregistro ?
                                <td colSpan={3} className="edit">Pendiente</td>
                                :
                                <>
                                    { dominio.dominio.dominioCore.estado.administrable && !dominio.esEliminadoBE ?
                                        <>
                                            <td className="edit" colSpan={colspanVer(dominio)}>
                                                {esDominioAccesible(dominio, false, true)}
                                            </td>
                                            { (domVencido(dominio) && puedeRenovar(dominio)) &&
                                                <td className="edit">
                                                    <a
                                                        onMouseOver={handleMouseOver}
                                                        onMouseOut={handleMouseOut}
                                                        style={{ cursor: isHovered ? 'pointer' : 'default' }}
                                                        onClick={() => handleRenovarDominio(dominio.dominio.dominioCore.nombre, dominio.dominio.dominioCore.id)}>Renovar</a>
                                                </td>
                                            }

                                            { (domEliminado(dominio) && esAdministrativoOTitular(dominio)) &&
                                                <td className="edit">
                                                    <a
                                                        onMouseOver={handleMouseOver}
                                                        onMouseOut={handleMouseOut}
                                                        style={{ cursor: isHovered ? 'pointer' : 'default' }}
                                                        onClick={() => handleLiberarDominio(dominio.dominio.dominioCore.nombre)} >Liberar</a>
                                                </td>
                                            }

                                            <td className="edit">
                                                { esAdministrativoOTitular(dominio) &&
                                                    <>
                                                        { dominio.eliminado ?
                                                            <>
                                                              <a
                                                                  onMouseOver={handleMouseOver}
                                                                  onMouseOut={handleMouseOut}
                                                                  style={{ cursor: isHovered ? 'pointer' : 'default' }}
                                                                  onClick={() => handleRecuperarDominio(dominio.dominio.dominioCore.nombre)} >Recuperar</a>
                                                            </>
                                                            :
                                                            <>
                                                                { !domVencido(dominio) &&
                                                                    <>
                                                                        <a id="id-eliminar-dominio-%{#varStatus.index}"
                                                                           onMouseOver={handleMouseOver}
                                                                           onMouseOut={handleMouseOut}
                                                                           style={{ cursor: isHovered ? 'pointer' : 'default' }}
                                                                           onClick={() => handleEliminarDominio(dominio.dominio.dominioCore.nombre, dominio.dominio.dominioCore.id)} >Eliminar</a>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </>
                                        :
                                        <>
                                            <td></td>
                                            <td colSpan={2}>
                                                { dominio.eliminado &&
                                                    // esta eliminado pero el usuario no lo puede recuperar, fue eliminado por el BE
                                                    <span>Eliminado</span>
                                                }
                                            </td>
                                        </>
                                    }
                                </>
                            }
                            </>
                        }
                    </tr>
                ))
                    :
                    <tr>
                        <td colSpan={12}>
                            No se encontraron dominios para administrar.
                        </td>
                    </tr>
                }
            </tbody>


            <tfoot>
                <tr>
                    <td colSpan={12}>
                        <Paginador
                            page2={pagActual}
                            sizePage2={cantPorPag}
                            totalRecords={cantResultados}
                            handleChangePage={handleChangePage}
                            mostrarCantElemXPag={true}
                            cantElemXPagMap={[5,10,25,50]}
                        />

                        <div className="right">
                            <button id="idSubmitBulk0" className="btn" onClick={modificarDatosContacto}>Modificar Datos Contacto</button>
                            <button id="idSubmitBulk1" className="btn" onClick={modificarCuenta}>Modificar Forma de Pago</button>
                            <button id="idSubmitBulk2" className="btn" onClick={eliminarFormaPago}>Eliminar Forma de Pago</button>
            {/*                <s:if test="transferenciaHabilitada">*/}
            {/*                <button id="idSubmitBulk3" className="btn">Transferir Titular</button>*/}
            {/*                </s:if>*/}
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>

        <div className="ayudaTabla">
            <ul>
                <li><span className="dominio-pago-asociado"></span> Sin forma de pago asociada </li>
                <li><span className="dominio-vencido" title="Dominio vencido"></span> Dominio vencido </li>
                <li><span className="dominio-eliminado" title="Dominio eliminado"></span> Dominio eliminado </li>
                <li><span className="dominio-registrado" title="Dominio registrado"></span> Dominio registrado </li>
            </ul>
        </div>

    </>
}