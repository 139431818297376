import http from '../support/HttpService';
import {buildNuevoTicket} from "../dto/TicketsDTOBuilder";

const REACT_APP_TICKET = process.env.REACT_APP_TICKET;

const TicketsService = {

    generarTicket: async function(ticket, tipoOperacion, captchaResponse) {
        try {

            const formData = buildNuevoTicket(ticket, tipoOperacion, captchaResponse);
            const response = await http.post(REACT_APP_TICKET, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return response.data;
        } catch (error) {
            console.error("Error generando ticket", error);
            return {
                respuestaOK: false,
                mensajeRespuesta: "Se produjo un error al procesar su solicitud. Por favor inténtelo nuevamente más tarde.",
            };
        }
    },

}
export default TicketsService;
